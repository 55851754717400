import { useCallback, useEffect, useRef } from 'react'

export interface MapProps {
  hiringArea: any
  allStatesCoordinates: any
}
type Shape = google.maps.Polygon | google.maps.Circle
const ONE_MILE = 1609.34
export default function MapInit({
  hiringArea,
  allStatesCoordinates
}: MapProps) {
  const ref = useRef<HTMLDivElement>(null)

  const initMap = useCallback(
    (div: HTMLDivElement) => {
      if (!allStatesCoordinates && !hiringArea) {
        return
      }

      const generatedMap: Shape[] = []
      const map = new google.maps.Map(div!, {
        center: { lat: 40.776773399535216, lng: -96.83487428360772 },
        zoom: 4,
        streetViewControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        zoomControl: false,
        scaleControl: false,
        rotateControl: false,
        fullscreenControl: false,
        gestureHandling: 'none'
      })

      map.setOptions({ draggableCursor: 'default' })
      let rule: any = {}
      if (hiringArea && hiringArea.rules && hiringArea.rules.in) {
        rule = hiringArea.rules.in
      } else if (allStatesCoordinates) {
        return
      }

      const allPoligons = (rule.polygons ? rule.polygons.coordinates || [] : [])
        .concat(rule.statesPolygon || [])
        .concat(allStatesCoordinates || [])

      if (rule.polygons && rule.polygons.coordinates.length) {
        const mapLines: any = []
        rule.polygons.coordinates.forEach((element: any) => {
          mapLines.push(
            element.map((item: any) => {
              return item.map((coord: any) => {
                return {
                  lng: coord[0],
                  lat: coord[1]
                }
              })
            })
          )
        })
      }

      if (allPoligons.length) {
        const mapLines: any = []
        allPoligons.forEach((element: any) => {
          mapLines.push(
            element.map((item: any) => {
              return item.map((coord: any) => {
                return {
                  lng: coord[0],
                  lat: coord[1]
                }
              })
            })
          )
        })

        mapLines.forEach((polygon: any) => {
          const bounds = new google.maps.Polygon({
            paths: polygon,
            clickable: false,
            strokeWeight: 1.0,
            strokeColor: '#3b73b9',
            fillColor: '#3b73b9'
          })
          generatedMap.push(bounds)
          bounds.setMap(map)
        })
      }

      const cities: any[] = rule.cities || []
      const zipCodes: any[] = rule.zipCodes || []

      for (const cityObj of cities) {
        if (!cityObj.city) {
          continue
        }
        const cityCircle = new google.maps.Circle({
          map,
          center: {
            lng: cityObj.city.geo.coordinates[0],
            lat: cityObj.city.geo.coordinates[1]
          },
          radius: cityObj.radius * ONE_MILE,

          strokeWeight: 1.0,
          strokeColor: '#3b73b9',
          fillColor: '#3b73b9'
        })
        generatedMap.push(cityCircle)
      }

      for (const zipCode of zipCodes) {
        if (!zipCode.geo) {
          continue
        }
        const zipCircle = new google.maps.Circle({
          map,
          center: {
            lng: zipCode.geo.coordinates[0],
            lat: zipCode.geo.coordinates[1]
          },

          strokeWeight: 1.0,
          strokeColor: '#3b73b9',
          fillColor: '#3b73b9',

          radius: zipCode.radius * ONE_MILE
        })
        generatedMap.push(zipCircle)
      }

      if (generatedMap.length) {
        const bounds = new google.maps.LatLngBounds()

        for (const polygon of generatedMap) {
          if (!('getPaths' in polygon)) {
            const bound = polygon.getBounds()
            if (bound) {
              bounds.union(bound)
            }
          } else {
            const paths = polygon.getPaths()
            for (let i = 0; i < paths.getLength(); i++) {
              const path = paths.getAt(i)
              for (let ii = 0; ii < path.getLength(); ii++) {
                bounds.extend(path.getAt(ii))
              }
            }
          }
        }

        map.fitBounds(bounds)
      }
    },
    [allStatesCoordinates, hiringArea]
  )

  useEffect(() => {
    const div = ref.current

    if (div) {
      initMap(div)
    }
  }, [initMap, ref])

  return (
    <div
      ref={ref}
      style={{
        maxWidth: '1200px',
        width: '100%',
        height: '50vw',
        maxHeight: '600px',
        margin: '0'
      }}
    />
  )
}
