import Link from 'next/link'
import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import useOnScreen from '../../../hooks/useOnScreen'
import classes from './ApplyCaption.module.scss'

interface Props {
  trackingNumber?: string
  applyNowTxt?: string
  backButtonTxt: string
  backButtonHref: string
  onApply: () => void
}
export default function ApplyCaption({
  trackingNumber,
  applyNowTxt = 'Apply Now!',
  backButtonTxt,
  backButtonHref,
  onApply
}: Props) {
  const containerRef = useRef<HTMLDivElement>(null)
  const rendered = useRef(false)
  const isVisible = useOnScreen(containerRef)

  useEffect(() => {
    rendered.current = true
  }, [])

  const showNumber = () => {
    if (trackingNumber)
      return (
        <Link
          href={`tel:${trackingNumber}`}
          type="button"
          className={classes.greenButton}>
          {trackingNumber}
        </Link>
      )
    else {
      return null
    }
  }

  const content = (
    <div className={classes.container}>
      <label>
        <div className={classes.actionButtons}>
          <button>{showNumber()}</button>
        </div>
      </label>
      <div className={classes.actionButtons}>
        <button onClick={() => onApply?.()} className={classes.redButton}>
          {applyNowTxt}
        </button>
        <Link className={classes.backButton} href={backButtonHref}>
          {backButtonTxt}
        </Link>
      </div>
    </div>
  )

  return (
    <>
      <div className={classes.applyCaption} ref={containerRef}>
        {content}
      </div>
      {!isVisible &&
        rendered.current &&
        createPortal(
          <div className={`${classes.applyCaption} fixed bottom-0`}>
            {content}
          </div>,
          document.getElementById('overlay-container')!
        )}
    </>
  )
}
