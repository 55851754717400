import { RefObject, useEffect, useMemo, useState } from 'react'

export default function useOnScreen(ref: RefObject<HTMLElement>) {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(() => {
    if (typeof window === 'undefined') {
      return
    }
    return new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting)
    })
  }, [])

  useEffect(() => {
    observer?.observe(ref.current!)
    return () => observer?.disconnect()
  }, [ref, observer])

  return isIntersecting
}
