import styled from 'styled-components'
import classes from './RelatedVideos.module.scss'
import { parseVideoData, youtube_parser } from '../../lib/Utils'
import { useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import Image from 'next/legacy/image'
import { BsYoutube } from 'react-icons/bs'
import VideoPlayer from '../video-player/VideoPlayer'

type Props = {
  text: string
  md?: number
}
export default function RelatedVideos({ text = '', md = 6 }: Props) {
  const [currentVideoUrl, setCurrentVideoUrl] = useState('')

  const processDetails = useMemo(() => {
    const relatedVideos: any[] = []
    const videos = youtube_parser(text) || []

    videos.forEach((video) => {
      relatedVideos.push(parseVideoData(video))
    })
    return relatedVideos
  }, [text])

  if (!processDetails.length) {
    return <></>
  }
  return (
    <>
      <div>
        <H3>Related Videos</H3>
        <Grid container spacing={2}>
          {processDetails.map((v, i) => (
            <Grid
              key={i}
              item
              xs={12}
              md={md}
              className={`${classes.imgItem} relative`}>
              <Image
                alt={`Video Image`}
                layout="responsive"
                objectPosition="top"
                objectFit="contain"
                width={640}
                height={480}
                src={v.src}
              />
              <div
                onClick={() => {
                  setCurrentVideoUrl(v.videoId)
                }}
                className={`${classes.youtubeIconWrp}`}>
                <BsYoutube className={`${classes.youtubeIcon}`} />
              </div>
            </Grid>
          ))}
        </Grid>
        <VideoPlayer videoUrl={currentVideoUrl} />
      </div>
    </>
  )
}

const H3 = styled.h3`
  font-family: Arial Black, sans-serif;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
`
