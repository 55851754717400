import MapInit from './mapInit'
import MapWrapper from './mapWrapper'

export interface MapProps {
  hiringArea: any
  allStatesCoordinates: any
}
export default function MapRender({ hiringArea, allStatesCoordinates }: MapProps) {
  return (
    <MapWrapper>
      <MapInit
        hiringArea={hiringArea}
        allStatesCoordinates={allStatesCoordinates}></MapInit>
    </MapWrapper>
  )
}
