import { useState, useEffect } from 'react'

const useFetchUrl = () => {
  const [urlData, setUrlData] = useState({
    currentUrl: '',
    domain: ''
  })

  useEffect(() => {
    const fetchUrl = async () => {
      const response = await fetch('/api/og-url')
      const data = await response.json()
      setUrlData({ currentUrl: data.currentUrl, domain: data.host })
    }

    fetchUrl()
  }, [])

  return urlData
}

export default useFetchUrl
